<template>
  <n-form
    ref="formRef"
    :label-width="100"
    :model="model"
    :rules="rules"
    label-placement="left"
  >
    <n-form-item :label="t('issues.create.form.subject.label')" path="subject">
      <n-input
        v-model:value="model.subject"
        type="text"
        :placeholder="t('Cissues.create.form.subject.placeholder')"
    /></n-form-item>
    <n-form-item :label="t('issues.create.form.description.label')" path="description">
      <n-input
        v-model:value="model.description"
        type="textarea"
        :placeholder="t('issues.create.form.description.placeholder')"
    /></n-form-item>
    <n-form-item label="Files" path="keys">
      <n-upload @before-upload="upload">
        <n-button>{{ t("commons.actions.upload") }}</n-button>
      </n-upload></n-form-item
    >
    <n-space justify="end">
      <n-button @click="handleCancelClick">
        {{ t("commons.actions.cancel") }}
      </n-button>
      <n-button type="primary" class="ml-3" @click="handleAddClick">
        {{ t("commons.actions.save") }}
      </n-button>
    </n-space>
  </n-form>
</template>
<script>
import { useMessage, NUpload, NButton, NForm, NFormItem, NInput, NSpace } from "naive-ui";
import { ref } from "vue";
import { useStore } from "vuex";
import { formatDates, getFileNameFromKey } from "@/shared/utils";
import FileReader from "@tanker/file-reader";
import { Storage } from "aws-amplify";
import { useI18n } from "vue-i18n";
export default {
  components: { NUpload, NButton, NForm, NFormItem, NInput, NSpace },
  props: {
    id: { required: true },
    nro_evidence: { required: true },
  },
  emits: ["onAdded", "onCancel"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const message = useMessage();
    const formRef = ref(null);
    const rules = ref({
      subject: {
        required: true,
        message: t("commons.form.validation.required"),
        trigger: ["input"],
      },
      description: [
        {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input"],
        },
      ],
    });
    const model = ref({
      id: props.nro_evidence,
      subject: "",
      description: "",
      datetime: formatDates(new Date()),
      owner: store.getters["auth/email"],
      ownerId: store.getters["auth/user_id"],
      keys: [],
    });
    const upload = async (data) => {
      const file = data.file;
      const reader = new FileReader(file.file);
      const blob = await reader.readAsArrayBuffer(20);
      const result = await store.dispatch("issues/isFileAllowed", blob);
      if (!result) {
        message.error(t("commons.form.validation.fileTypeNotAllowed"));
        return false;
      }
      data.status = t("commons.actions.uploading");
      await store.dispatch("app/lockUI");
      await Storage.put(
        `issues/${props.id}/${new Date().getTime()}-${file.name}`,
        file.file,
        {
          resumable: true,
          level: "protected",
          identityId: store.getters["auth/user_id"],
          contentType: file.type,
          completeCallback: async (event) => {
            model.value.keys.push(getFileNameFromKey(event.key));
            data.percentage = 100;
            await store.dispatch("app/unlockUI");
          },
          progressCallback: (progress) => {
            data.percentage = (progress.loaded / progress.total) * 100;
          },
          errorCallback: async () => {
            data.status = "error";
            await store.dispatch("app/unlockUI");
          },
        }
      );
      return true;
    };

    const handleAddClick = (e) => {
      e.preventDefault();
      formRef.value?.validate(async (errors) => {
        console.log(errors);
        if (!errors) {
          await store.dispatch("app/lockUI");
          store
            .dispatch("issues/AddEvidence", model.value)
            .then(async () => {
              await store.dispatch("app/unlockUI");
              emit("onAdded");
            })
            .catch(async () => store.dispatch("app/unlockUI"));
        }
      });
    };
    const handleCancelClick = async (e) => {
      e.preventDefault();
      await store.dispatch("app/lockUI");
      model.value.keys.forEach(async (item) =>
        Storage.remove(`issues/${props.id}/${getFileNameFromKey(item)}`, {
          level: "protected",
          identityId: store.getters["auth/user_id"],
        })
      );
      await store.dispatch("app/unlockUI");
      emit("onCancel");
    };
    return {
      t,
      model,
      upload,
      rules,
      formRef,
      handleAddClick,
      handleCancelClick,
    };
  },
};
</script>
