<template>
  <n-grid cols="1 s:8" responsive="screen" style="padding-top: 30px">
    <n-gi span="1 s:5" offset="0 s:1">
      <n-steps vertical :current="currentStep" :status="currentStatus" class="ml-2">
        <n-step :title="`${t('issues.title')} ${model.rent.publication.title}`">
          <n-form
            ref="formRef"
            :label-width="100"
            :model="model"
            :rules="rules"
            class="my-3"
            label-placement="left"
            v-if="currentStep === 1"
          >
            <n-form-item
              :label="t('issues.create.form.subject.label')"
              path="form.subject"
            >
              <n-input
                v-model:value="model.form.subject"
                type="text"
                :placeholder="t('issues.create.form.subject.placeholder')"
              />
            </n-form-item>
            <n-form-item
              :label="t('issues.create.form.description.label')"
              path="form.description"
            >
              <n-input
                v-model:value="model.form.description"
                type="textarea"
                :placeholder="t('issues.create.form.description.placeholder')"
              />
            </n-form-item>
            <n-space justify="end">
              <n-button @click="handleCancelClick">
                {{ t("commons.actions.cancel") }}
              </n-button>
              <n-button type="primary" class="ml-3" @click="handleCreateClick">
                {{ t("commons.actions.create") }}
              </n-button>
            </n-space>
          </n-form>
        </n-step>
        <n-step
          :title="t('commons.actions.add', { reference: t('commons.labels.file', 2) })"
        >
          <div v-if="currentStep === 2">
            <n-space class="my-2">
              {{ t("issues.create.dialogs.wantToAddEvidences.content") }}
              <n-radio
                name="rdEvidence"
                :checked="addedEvidence"
                value="1"
                @change="handleChange"
              >
                {{ t("commons.confirmation.yes") }}
              </n-radio>
              <n-radio
                name="rdEvidence"
                :checked="!addedEvidence"
                value="0"
                @change="handleChange"
              >
                {{ t("commons.confirmation.no") }}
              </n-radio>
            </n-space>
            <form-evidende
              :id="selectedIssue.id"
              :nro_evidence="1"
              v-if="addedEvidence"
              @onAdded="handleAddedEvidence"
              @onCancel="handleCancelEvidence"
            />
            <n-button type="primary" @click="goToViewDetail" v-if="!addedEvidence">
              {{ t("commons.confirmation.ok") }}
            </n-button>
          </div>
        </n-step>
      </n-steps>
    </n-gi>
  </n-grid>
</template>
<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import {
  NFormItem,
  NInput,
  NSpace,
  NButton,
  NForm,
  useDialog,
  NSteps,
  NStep,
  NGrid,
  NGi,
  NRadio,
} from "naive-ui";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { formatDates } from "@/shared/utils";
import FormEvidende from "../../components/issues/FormEvidence.vue";
import { useI18n } from "vue-i18n";

export default {
  components: {
    NFormItem,
    NInput,
    NSpace,
    NButton,
    NForm,
    NSteps,
    NStep,
    NGrid,
    NGi,
    FormEvidende,
    NRadio,
  },
  setup(_props, { attrs }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const dialog = useDialog();
    const formRef = ref(null);
    const currentStepRef = ref(1);
    const currentStatusRef = ref("process");
    const addedEvidenceRef = ref(false);
    const selectedIssue = computed(() => store.state.issues.selectedIssue);
    const modelRef = ref({
      form: {
        subject: null,
        description: null,
        datetime: formatDates(new Date()),
        state: "OPEN",
        rentId: attrs.rentId,
        relatedUsers: [],
        snapshotRentState: null,
      },
      rent: {
        publication: {
          title: "Loading...",
        },
      },
    });
    const rules = ref({
      form: {
        subject: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input"],
        },
        description: [
          {
            required: true,
            message: t("commons.form.validation.required"),
            trigger: ["input"],
          },
        ],
      },
    });
    const goToViewDetail = () =>
      router.push({
        name: "view-rent-detail",
        params: { id: attrs.rentId },
      });

    onMounted(() => {
      store.dispatch("maintainer_rents/loadDetail", { id: attrs.rentId }).then((r) => {
        if (r.state === "ARBITRATION_IN_PROCESS") {
          dialog.warning({
            title: t("IssuesPage.CreatePage.onMounted.title"),
            content: t("IssuesPage.CreatePage.onMounted.content"),
            positiveText: t("IssuesPage.CreatePage.onMounted.positiveText"),
            onPositiveClick: goToViewDetail,
          });
        }
        modelRef.value.rent = r;
        modelRef.value.form.snapshotRentState = r.state;
        modelRef.value.form.relatedUsers = r.relatedUsers;
      });
    });

    onUnmounted(async () => {
      await store.dispatch("maintainer_rents/unloadDetail");
    });

    const handleCancelClick = (e) => {
      e.preventDefault();
      goToViewDetail();
    };

    const handleCreateClick = (e) => {
      e.preventDefault();
      formRef.value?.validate(async (errors) => {
        if (!errors) {
          currentStatusRef.value = "process";
          await store.dispatch("app/lockUI");
          store.dispatch("issues/SaveIssue", modelRef.value.form).then(async () => {
            await store.dispatch("app/unlockUI");
            currentStepRef.value = 2;
          });
        } else {
          currentStatusRef.value = "error";
        }
      });
    };

    const handleAddedEvidence = () => {
      dialog.success({
        title: t("issues.create.dialogs.issueCreated.title"),
        content: t("issues.create.dialogs.issueCreated.content"),
        positiveText: t("issues.create.dialogs.issueCreated.positiveButton.text"),
        onPositiveClick: goToViewDetail,
      });
    };

    const handleCancelEvidence = () => {
      dialog.success({
        title: t("issues.create.dialogs.issueCreated.title"),
        content: `${t("issues.create.dialogs.issueCreated.content")} (${t(
          "issues.create.dialogs.issueCreated.extra"
        )})`,
        positiveText: t("issues.create.dialogs.issueCreated.positiveButton.text"),
        onPositiveClick: goToViewDetail,
      });
    };

    return {
      t,
      model: modelRef,
      rules,
      formRef,
      handleCreateClick,
      handleCancelClick,
      selectedIssue,
      addedEvidence: addedEvidenceRef,
      currentStep: currentStepRef,
      currentStatus: currentStatusRef,
      handleAddedEvidence,
      handleCancelEvidence,
      goToViewDetail,
      handleChange: () => {
        addedEvidenceRef.value = !addedEvidenceRef.value;
      },
    };
  },
};
</script>
